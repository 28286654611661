<template>
	<div class="form-wrapper small">
		<div class="form-container">
			<form @submit.prevent="onSubmit">

				<form-errors :errors="errors"></form-errors>

				<div class="input-wrapper">
					<div class="input-title">Account E-mail</div>
					<div class="input-box"><input type="text" v-model="form.email"></div>
				</div>
				<div class="input-wrapper">
					<div class="input-title">New Password</div>
					<div class="input-box"><input type="password" v-model="form.password"></div>
				</div>
				<div class="input-wrapper">
					<div class="input-title">Confirm New Password</div>
					<div class="input-box"><input type="password" v-model="form.password_confirmation"></div>
				</div>
				<submit-button text="Reset Password" icon="fa-key" :loading="formSaving"></submit-button>
			</form>
		</div>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';

export default {

	name: 'ResetPassword',

	props: [ 'token' ],

	mixins: [ FormMixin ],

	data() {
		return {
			loading: false,
			form: { token: this.token, email: this.$route.query.email || '' },
			errors: {},
		};
	},

	methods: {

		async onSubmit() {
			if (!this.validate()) return;

			this.submit(async () => {

				await this.$api.post('/password/reset/' + this.token, this.form);

				this.$store.dispatch('addToast', 'Your password has been reset');
				this.$router.replace('/login');
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to reset your password');
			});
		},

		validate() {
			this.errors = {};

			if (!this.form.email || this.form.email.length === 0) {
				this.errors.email = 'Your e-mail address is required';
			}

			if (!this.form.password || this.form.password.length === 0) {
				this.errors.password = 'A new password is required';
			} else if (!this.form.password_confirmation || this.form.password_confirmation.length === 0) {
				this.errors.password = 'You must confirm your password';
			} else if (this.form.password.toLowerCase() !== this.form.password_confirmation.toLowerCase()) {
				this.errors.password = 'Your passwords do not match';
			}

			return Object.keys(this.errors).length === 0;
		},

	}
};

</script>
